import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Badge from "@mui/material/Badge";
import swal from "sweetalert";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Authapi from "../Services/Authapi";
import ls from "local-storage";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import * as RiIcons from "react-icons/ri";
// import * as CgIcons from "react-icons/cg";
// import * as BsIcons from "react-icons/bs";
// import * as FiIcons from "react-icons/fi";
// import * as IoIcons from "react-icons/io5";
// import * as HiIcons from "react-icons/hi";
// import * as GrIcons from "react-icons/gr";
// import * as DiIcons from "react-icons/di";
import "./detailedNotifications.css";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import AuthCheckComponent from '../../Layouts/Token';
import AuthCheckComponent from "./Token";
// import ClearAllIcon from '@mui/icons-material/ClearAll';

const allIcons = {
  ...FaIcons,
  ...MdIcons,
  ...GiIcons,
  // ...AiIcons,
  // ...BiIcons,
  // ...RiIcons,
  // ...CgIcons,
  // ...BsIcons,
  // ...FiIcons,
  // ...IoIcons,
  // ...HiIcons,
  // ...GrIcons,
  // ...DiIcons,
  // Add more icon packs here if needed
};

const iconStyle = { color: "#1b6e95", marginRight: "8px", fontSize: "24px" };
const headerStyle = { backgroundColor: "#1b6e95", color: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "04px" };

function formatDate(dateString) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata"
  };
  return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString)).replace(/\//g, '-');
}

const DetailedNotifications = (props) => {
  const [notifications, setNotifications] = useState({});
  const [readNotifications, setReadNotifications] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("All Notifications");
  const [notificationIcons, setNotificationIcons] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [sidebarExpandedGroups, setSidebarExpandedGroups] = useState({});
  const history = useHistory();
  const [openEventModal, setOpenEventModal] = useState(false);
  const [openEventModal1, setOpenEventModal1] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const User = ls("user");
  const [showApprovedDetailsPopup, setShowApprovedDetailsPopup] = useState(false);
  const [selectedLeaveDetails, setSelectedLeaveDetails] = useState({});
  // const [showModalFromNotification, setShowModalFromNotification] = useState(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState({});
  const [isReasonExpanded, setIsReasonExpanded] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    setLoading(true);
    try {
      let notificationsData =
        ls("roles") === "Super Admin"
          ? await Authapi.Notification()
          : await Authapi.NotificationEmployee();
      setNotifications(notificationsData);

      let readNotificationsData =
        ls("roles") === "Super Admin"
          ? await Authapi.Readedadminnotifications()
          : await Authapi.Readedemployeenotifications();
      setReadNotifications(readNotificationsData);

      const iconsResponse = await Authapi.getNotificationIcons();
      if (iconsResponse) {
        const transformedIcons = {};
        Object.keys(iconsResponse).forEach((key) => {
          transformedIcons[key.replace(/_/g, " ")] = iconsResponse[key];
        });
        setNotificationIcons(transformedIcons);
      }

      const initialExpandedGroups = {};
      Object.keys(notificationsData).forEach((type) => {
        initialExpandedGroups[type] = true;
      });
      Object.keys(readNotificationsData).forEach((type) => {
        initialExpandedGroups[`Readed_${type}`] = true;
      });
      setExpandedGroups(initialExpandedGroups);

      const initialSidebarExpandedGroups = {
        "All Notifications": true,
        "Read Notifications": true,
      };
      Object.keys(notificationsData).forEach((type) => {
        initialSidebarExpandedGroups[type] = false;
      });
      Object.keys(readNotificationsData).forEach((type) => {
        initialSidebarExpandedGroups[`Readed_${type}`] = false;
      });
      setSidebarExpandedGroups(initialSidebarExpandedGroups);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  const handleClose = () => {
    setAnchorEl(null);
    fetchData();
  };

  const fetchEventDetails = async (eventId) => {
    const allEvents = await Authapi.getAllEvents();
    if (allEvents && allEvents.status === true) {
      const eventDetail = allEvents.data.find(event => event.id === eventId);

      if (eventDetail) {
        setEventDetails(eventDetail);
        setOpenEventModal(true);
      }
    }
  };

  const timeenter = async (eventId) => {
    const allEvents = await Authapi.chekUserData();
    if (allEvents && allEvents.status === true) {
      const eventDetail = allEvents.data.find(event => event.id === eventId);
      console.log(eventDetail)
      if (eventDetail) {
        setSelectedTimeEntry(eventDetail);
        setOpenEventModal1(true);
      }
    }
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
    setOpenEventModal1(false);
  };

  const fetchTicketDetails = async (dataId) => {
    try {
      const tickets = await Authapi.getTickets1();
      if (tickets && tickets.status === true) {
        const numericDataId = Number(dataId);
        const ticket = tickets.data.find(ticket => ticket.id === numericDataId);
        if (ticket) {
          setSelectedLeaveDetails(ticket);
          setShowApprovedDetailsPopup(true);
        } else {
          console.error("Ticket not found for ID:", numericDataId);
        }
      } else {
        console.error("Error fetching tickets:", tickets);
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await Authapi.Notificationread(notification.id);
      const notificationData = {
        id: notification.id,
        type: notification.type,
        dataId: notification.data_id,
      };
      sessionStorage.setItem("fromNotificationPanel", "true");
      if (notification.type === "Leave Ticket" || notification.type === "Leave Status") {
        await fetchTicketDetails(notification.data_id);
      } else if (notification.type === "Upcoming Event") {
        await fetchEventDetails(notification.data_id);
      } else if (notification.type === "Time-Entry" || notification.type === "Time-Entry Status") {
        await timeenter(notification.data_id);
      }
      switch (notification.type) {
        // case "Leave Ticket":
        // case "Leave Status":
        //   history.push({
        //     pathname: "/tickets",
        //     state: { notificationData },
        //   });
        //   break;
        // case "Time-Entry":
        //   sessionStorage.setItem("fromNotificationPanel", "true");
        //   history.push({
        //     pathname: "/check",
        //     state: { notificationData },
        //   });
        //   break;
        // case "Time-Entry Status":
        //   sessionStorage.setItem('fromNotificationPanel', 'true');
        //   history.push({
        //     pathname: "/check",
        //     state: { notificationData }
        //   });
        //   break;
        // case "Upcoming Event":
        //   const eventId = notification.data_id;
        //   sessionStorage.setItem("openEventModal", "true");
        //   history.push({
        //     pathname: "/dashboard",
        //     state: { eventId },
        //   });
        //   break;
        case "New Holiday":
          const dateMatch = notification.notification.match(/\d{2}-\d{2}-\d{4}/);
          if (dateMatch) {
            const date = dateMatch[0];
            sessionStorage.setItem('openHolidayModal', 'true');
            history.push({
              pathname: "/leaves",
              state: { date }
            });
          }
          break;
        case "Resign-Letter":
          history.push("/Relievingletter");
          break;
        case "Birthday":
          history.push("/dashboard");
          break;
        case "Salary-Slip":
          sessionStorage.setItem("openSalaryModal", "true");
          history.push({
            pathname: `/users/${notification.user_id}`,
            state: { tab: "salaryInfo", salarySlipId: notification.data_id },
          });
          break;
        default:
          break;
      }

      handleClose();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleExpandClick = (type) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleSidebarExpandClick = (type) => {
    setSidebarExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleMarkAllAsRead = async (type) => {

    if (ls("roles") === "Super Admin") {
      // try {
      //   swal({
      //     title: "Loading",
      //     text: "Please wait...",
      //     buttons: false,
      //     closeOnClickOutside: false,
      //     closeOnEsc: false,
      //     width: "10%",
      //     content: {
      //       element: "img",
      //       attributes: {
      //         src: "https://www.boasnotas.com/img/loading2.gif",
      //         style: "width: 50px;"
      //       }
      //     }
      //   });
      //   const response = await Authapi.adminnotifiwise(type);
      //   if (response.status === true) {
      //     fetchData();
      //     swal("Success", "All notifications marked as read!", "success");
      //   }
      // } catch (error) {
      //   console.error("Error marking all notifications as read:", error);
      //   swal("Error", "Failed to mark all notifications as read.", "error");
      // }
      try {
        const willProceed = await swal({
          title: "Are you sure?",
          text: "Do you want to mark all notifications as read?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        });

        if (willProceed) {
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });
          const response = await Authapi.adminnotifiwise(type);
          if (response.status === true) {
            fetchData();
            swal("Success", "All notifications marked as read!", "success");
          }
        } else {
          swal("Cancelled", "The action was cancelled.", "info");
        }
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        swal("Error", "Failed to mark all notifications as read.", "error");
      }

    } else {
      try {
        const willProceed = await swal({
          title: "Are you sure?",
          text: "Do you want to mark all notifications as read?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        });
        if (willProceed) {
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });
          const response = await Authapi.employeenotifiwise(type);
          if (response.status === true) {
            fetchData()
            swal("Success", "All notifications marked as read!", "success");
          }
        } else {
          swal("Cancelled", "The action was cancelled.", "info");
        }
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        swal("Error", "Failed to mark all notifications as read.", "error");
      }
    };
  }

  const renderNotificationsByType = (type, notificationsData) => {
    if (!notificationsData[type]) return null;

    return notificationsData[type].map((notification, index) => (
      <Card
        key={notification.id}
        className="notification-card"
        onClick={() => handleNotificationClick(notification)}
        style={{ marginTop: "10px" }}
      >
        <CardContent style={{ padding: "12px" }}>
          <Typography variant="body2" component="p">
            {index + 1}. {notification.notification}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            className="notification-date"
          >
            {formatDate(notification.created_at)}
          </Typography>
        </CardContent>
      </Card>
    ));
  };

  const renderNotifications = () => {
    const isReadedGroup = selectedGroup.startsWith("Readed_");
    const groupType = isReadedGroup ? selectedGroup.replace("Readed_", "") : selectedGroup;

    if (selectedGroup === "All Notifications") {
      return (
        <>
          {Object.keys(notifications).map((type) => (
            <div key={type} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    {type}
                  </Typography>
                </div>
                <div>
                  {!isReadedGroup && (
                    <IconButton
                      onClick={() => handleMarkAllAsRead(type)}
                      style={{ color: "#fff" }}
                      title="Mark all as read"
                    >
                      <DoneAllIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => handleExpandClick(type)}
                    style={{ color: "#fff" }}
                  >
                    {expandedGroups[type] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
              </div>
              <Collapse in={expandedGroups[type]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, notifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))}
          {/* {Object.keys(readNotifications).map((type) => (
            <div key={`Readed_${type}`} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    Readed {type}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(`Readed_${type}`)}
                  style={{ color: "#fff" }}
                >
                  {expandedGroups[`Readed_${type}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedGroups[`Readed_${type}`]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, readNotifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))} */}
        </>
      );
    } else if (selectedGroup === "Read Notifications") {
      return (
        <>
          {Object.keys(readNotifications).map((type) => (
            <div key={`Readed_${type}`} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    {type}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(`Readed_${type}`)}
                  style={{ color: "#fff" }}
                >
                  {expandedGroups[`Readed_${type}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedGroups[`Readed_${type}`]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, readNotifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))}
        </>
      );
    } else {
      return (
        <div className="notification-group">
          <div
            className="notification-group-header"
            style={{
              backgroundColor: "#1b6e95",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "04px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {allIcons[notificationIcons[groupType]] ? (
                React.createElement(
                  allIcons[notificationIcons[groupType]],
                  { style: { ...iconStyle, color: "#fff" } }
                )
              ) : (
                <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
              )}
              <Typography
                variant="h6"
                className="notification-group-title"
                style={{ color: "#fff" }}
              >
                {isReadedGroup ? ` ${groupType}` : groupType}
              </Typography>
              {!isReadedGroup && (
                <IconButton
                  onClick={() => handleMarkAllAsRead(groupType)}
                  style={{
                    color: "#fff", position: "absolute",
                    right: "59px"
                  }}
                  title="Mark all as read"
                >
                  <DoneAllIcon />
                </IconButton>
              )}
            </div>
            <IconButton
              onClick={() => handleExpandClick(selectedGroup)}
              style={{ color: "#fff" }}
            >
              {expandedGroups[selectedGroup] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </div>
          <Collapse
            in={expandedGroups[selectedGroup]}
            timeout="auto"
            unmountOnExit
          >
            {renderNotificationsByType(groupType, isReadedGroup ? readNotifications : notifications)}
          </Collapse>
          <Divider className="notification-divider" />
        </div>
      );
    }
  };

  const getTotalNotificationCount = () => {
    return Object.keys(notifications).reduce(
      (total, type) => total + notifications[type].length,
      0
    );
  };

  const getTotalReadNotificationCount = () => {
    return Object.keys(readNotifications).reduce(
      (total, type) => total + readNotifications[type].length,
      0
    );
  };
  // const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
  //   ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
  //   : [];

  const isAdmin = User && User.roles && User.roles.some(role => role.name === "Super Admin");

  // const updateLeaveStatus = async (id, status) => {
  //   if (status === "Declined") {
  //     swal({
  //       title: "Enter Reason",
  //       text: "Please enter the reason for declining the leave:",
  //       content: {
  //         element: "input",
  //         attributes: {
  //           placeholder: "Type your reason here",
  //           type: "text",
  //         },
  //       },
  //       buttons: {
  //         cancel: true,
  //         confirm: {
  //           text: "OK",
  //           closeModal: false,
  //         },
  //       },
  //     }).then((inputReason) => {
  //       if (!inputReason) {
  //         swal("Declined", "You need to write a reason!", "error");
  //         return;
  //       }
  //       const user = ls("user");
  //       const userName = `${user.firstName} ${user.lastName}`;

  //       const ticketUpdateData = {
  //         leaveStatus: status,
  //         c_reason: status === "Declined" ? inputReason : "",
  //         // cancel_reason: newStatus !== "Cancelled" ? reason : "",
  //         updated_by: userName,
  //       };
  //       this.updateTicketLeaveStatus(ticketUpdateData, id);
  //     });
  //     return;
  //   } else {
  //     try {
  //       const user = ls("user");
  //       const userName = `${user.firstName} ${user.lastName}`;

  //       const ticketUpdateData = {
  //         leaveStatus: status,
  //         // c_reason: newStatus === "Cancelled" ? reason : "",
  //         // cancel_reason: newStatus !== "Cancelled" ? reason : "",
  //         updated_by: userName,
  //       };
  //       await Authapi.updateTicketLeaveStatus(ticketUpdateData, id);
  //     } catch (error) {
  //       console.error("Error updating leave status:", error);
  //     }
  //   }

  // };

  // const updateLeaveStatus = async (id, status) => {
  //   if (status === "Declined") {
  //     swal({
  //       title: "Enter Reason",
  //       text: "Please enter the reason for declining the leave:",
  //       content: {
  //         element: "input",
  //         attributes: {
  //           placeholder: "Type your reason here",
  //           type: "text",
  //         },
  //       },
  //       buttons: {
  //         cancel: true,
  //         confirm: {
  //           text: "OK",
  //           closeModal: false,
  //         },
  //       },
  //     }).then(async (inputReason) => {
  //       if (!inputReason) {
  //         swal("Declined", "You need to write a reason!", "error");
  //         return;
  //       }
  //       const user = ls("user");
  //       const userName = `${user.firstName} ${user.lastName}`;

  //       const ticketUpdateData = {
  //         leaveStatus: status,
  //         c_reason: inputReason,
  //         updated_by: userName,
  //       };

  //       try {
  //         await Authapi.updateTicketLeaveStatus(ticketUpdateData, id);
  //         swal("Success", "Leave status updated successfully!", "success");
  //       } catch (error) {
  //         console.error("Error updating leave status:", error);
  //         swal("Error", "There was an error updating the leave status.", "error");
  //       }
  //     });
  //     return;
  //   } else {
  //     const confirmUpdate = await swal({
  //       title: "Confirm Update",
  //       text: `Are you sure you want to mark this leave as ${status}?`,
  //       icon: "warning",
  //       buttons: ["Cancel", "Confirm"],
  //       dangerMode: true,
  //     });

  //     if (confirmUpdate) {
  //       try {
  //         const user = ls("user");
  //         const userName = `${user.firstName} ${user.lastName}`;

  //         const ticketUpdateData = {
  //           leaveStatus: status,
  //           updated_by: userName,
  //         };
  //         await Authapi.updateTicketLeaveStatus(ticketUpdateData, id);
  //       } catch (error) {
  //         console.error("Error updating leave status:", error);
  //       }
  //     }
  //   }
  // };


  const updateLeaveStatus = async (id, status) => {
    if (status === "Declined") {
      swal({
        title: "Enter Reason",
        text: "Please enter the reason for declining the leave:",
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your reason here",
            type: "text",
          },
        },
        buttons: {
          cancel: true,
          confirm: {
            text: "OK",
            closeModal: false,
          },
        },
      }).then(async (inputReason) => {
        if (!inputReason) {
          swal("Declined", "You need to write a reason!", "error");
          return;
        }

        const user = ls("user");
        const userName = `${user.firstName} ${user.lastName}`;

        const ticketUpdateData = {
          leaveStatus: status,
          c_reason: inputReason,
          updated_by: userName,
        };

        try {
          // swal({
          //   title: "Updating...",
          //   text: "Please wait while we update the leave status.",
          //   icon: "info",
          //   buttons: false,
          //   closeOnClickOutside: false,
          //   closeOnEsc: false,
          // });
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });
          await Authapi.updateTicketLeaveStatus(ticketUpdateData, id);
          swal("Success", "Leave status updated successfully!", "success");
        } catch (error) {
          console.error("Error updating leave status:", error);
          swal("Error", "There was an error updating the leave status.", "error");
        }
      });
      return;
    } else {
      const confirmUpdate = await swal({
        title: "Confirm Update",
        text: `Are you sure you want to mark this leave as ${status}?`,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      });

      if (confirmUpdate) {
        try {
          // swal({
          //   title: "Updating...",
          //   text: "Please wait while we update the leave status.",
          //   icon: "info",
          //   buttons: false, 
          //   closeOnClickOutside: false,
          //   closeOnEsc: false,
          // });
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });

          const user = ls("user");
          const userName = `${user.firstName} ${user.lastName}`;

          const ticketUpdateData = {
            leaveStatus: status,
            updated_by: userName,
          };
          await Authapi.updateTicketLeaveStatus(ticketUpdateData, id);

          swal("Success", `Leave status updated to ${status} successfully!`, "success");
        } catch (error) {
          console.error("Error updating leave status:", error);
          swal("Error", "There was an error updating the leave status.", "error");
        }
      }
    }
  };


  const accept = async (id) => {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;

    const ticketUpdateData = {
      is_approved: "0",
      userName: userName,
    };

    swal({
      title: "Are you sure?",
      text: "Do you want to approve this entry?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let response = await Authapi.approveTime(ticketUpdateData, id);
        if (response && response.status === true) {
          swal("Done!", "You have approved the entry!", "success");
          setOpenEventModal1(false);
        } else {
          swal("Error!", "There was an error approving the entry.", "error");
        }
      }
    });
  };

  const decline = async (id) => {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;

    const ticketUpdateData = {
      is_approved: "2",
      userName: userName,
    };

    swal({
      title: "Are you sure?",
      text: "Do you want to reject this entry?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let userCheck = await Authapi.approveTime(ticketUpdateData, id);
        if (userCheck && userCheck.status === true) {
          swal("Done!", "You have rejected the entry!", "success");
          setOpenEventModal1(false);
        } else {
          swal("Error!", "There was an error rejecting the entry.", "error");
        }
      }
    });
  };

  const toggleReasonExpansion = () => {
    setIsReasonExpanded(prevState => !prevState);
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        setAutUser={props.setAutUser}
        component={
          <>
            <div>
              <Card style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...props}
                    primaryPageName="All Notifications"
                    primaryPageLink="/DetailedNotifications"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
            </div>

            <div className="detailed-notifications-container">
              <div className="sidebar">
                <List component="nav">
                  <ListItem
                    button
                    selected={selectedGroup === "All Notifications"}
                    onClick={() => handleGroupClick("All Notifications")}
                    style={headerStyle}
                  >
                    <div style={{ display: "contents", alignItems: "center" }}>
                      <ListItemIcon>
                        <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`All Notifications (${getTotalNotificationCount()})`}
                        style={{ color: "#fff" }}
                      />
                    </div>
                    <IconButton
                      onClick={() => handleSidebarExpandClick("All Notifications")}
                      style={{ color: "#fff" }}
                    >
                      {sidebarExpandedGroups["All Notifications"] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={sidebarExpandedGroups["All Notifications"]} timeout="auto" unmountOnExit>
                    {Object.keys(notifications).map((type) => (
                      <ListItem
                        button
                        key={type}
                        selected={selectedGroup === type}
                        onClick={() => handleGroupClick(type)}
                      >
                        <ListItemIcon>
                          {allIcons[notificationIcons[type]] ? (
                            React.createElement(
                              allIcons[notificationIcons[type]],
                              { style: iconStyle }
                            )
                          ) : (
                            <NotificationsIcon style={iconStyle} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={`${type} (${notifications[type].length})`}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                  <ListItem
                    button
                    selected={selectedGroup === "Read Notifications"}
                    onClick={() => handleGroupClick("Read Notifications")}
                    style={headerStyle}
                  >
                    <div style={{ display: "contents", alignItems: "center" }}>
                      <ListItemIcon>
                        <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Read Notifications (${getTotalReadNotificationCount()})`}
                        style={{ color: "#fff" }}
                      />
                    </div>
                    <IconButton
                      onClick={() => handleSidebarExpandClick("Read Notifications")}
                      style={{ color: "#fff" }}
                    >
                      {sidebarExpandedGroups["Read Notifications"] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={sidebarExpandedGroups["Read Notifications"]} timeout="auto" unmountOnExit>
                    {Object.keys(readNotifications).map((type) => (
                      <ListItem
                        button
                        key={`Readed_${type}`}
                        selected={selectedGroup === `Readed_${type}`}
                        onClick={() => handleGroupClick(`Readed_${type}`)}
                      >
                        <ListItemIcon>
                          {allIcons[notificationIcons[type]] ? (
                            React.createElement(
                              allIcons[notificationIcons[type]],
                              { style: iconStyle }
                            )
                          ) : (
                            <NotificationsIcon style={iconStyle} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={`${type} (${readNotifications[type].length})`}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                </List>
              </div>
              <div className="notifications-content">
                {loading ? (
                  <p>Loading...</p>
                ) : Object.keys(notifications).length > 0 ||
                  Object.keys(readNotifications).length > 0 ? (
                  renderNotifications()
                ) : (
                  <p>No notifications available</p>
                )}
              </div>
            </div>
          </>
        }
      />
      <Footer {...props} />
      <Dialog
        open={openEventModal}
        onClose={handleCloseEventModal}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            // padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            width: "500px",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <DialogTitle
            style={{
              textAlign: "center",
              padding: "5px 0",
              backgroundColor: "#1b6e95",
              color: "#fff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            Upcoming Event Details
            <CloseIcon
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
                color: "#fff",
                backgroundColor: "#f44336",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={handleCloseEventModal}
            />
          </DialogTitle>
          <DialogContent>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{eventDetails.name}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{eventDetails.date}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Description</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{eventDetails.description}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px",}}><b>Image</b></td>
                    <td style={{ padding: "8px", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px",}}>
                      <img src={eventDetails.image} alt={eventDetails.name} style={{ maxWidth: "200px", maxHeight: "111px" }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        open={showApprovedDetailsPopup}
        onClose={() => setShowApprovedDetailsPopup(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            // padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            width: "600px",
            height: "370px"
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <DialogTitle
            style={{
              textAlign: "center",
              padding: "5px 0",
              backgroundColor: "#1b6e95",
              color: "#fff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            Leave Details
            <CloseIcon
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
                color: "#fff",
                backgroundColor: "#f44336",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={() => setShowApprovedDetailsPopup(false)}
            />
          </DialogTitle>
          <DialogContent>
            {selectedLeaveDetails && (
              <>
                <div>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.firstName}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Leave Type</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.leaveType}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Created On</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.created_at}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px",}}><b>Reason for Leave</b></td>
                        <td style={{ padding: "8px", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px",}}>
                          {selectedLeaveDetails.ticket_mesasge ? (
                            <>
                              {isReasonExpanded || selectedLeaveDetails.ticket_mesasge.length <= 50
                                ? selectedLeaveDetails.ticket_mesasge
                                : `${selectedLeaveDetails.ticket_mesasge.substring(0, 50)}...`}
                              {selectedLeaveDetails.ticket_mesasge.length > 50 && (
                                <span
                                  onClick={toggleReasonExpansion}
                                  style={{ color: '#1b6e95', cursor: 'pointer', marginLeft: '5px', display: 'inline-block' }}
                                >
                                  {isReasonExpanded ? 'Read Less' : 'Read More'}
                                </span>
                              )}
                            </>
                          ) : 'N/A'}
                        </td>
                      </tr>
                      <tr>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date Range</b></td>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
                {selectedLeaveDetails.date_range ? (() => {
                  const [startDate, endDate] = selectedLeaveDetails.date_range.split(' to ');
                  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                  return `${startDate} (${capitalize(selectedLeaveDetails.firstleavetype)}) To ${endDate} (${capitalize(selectedLeaveDetails.secondleavetype)})`;
                })() : 'N/A'}
              </td>
            </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>No Of Days</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.no_of_days}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Status</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {isAdmin && selectedLeaveDetails.status === "Pending" && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                      margin: "5px"
                    }}
                  >
                    <Tooltip title="Approve">
                      <Button
                        onClick={() => {
                          updateLeaveStatus(selectedLeaveDetails.id, "Approved");
                          setShowApprovedDetailsPopup(false);
                        }}
                        variant="contained"
                        color="primary"
                        style={{ margin: "5px", backgroundColor: "#1B6E95" }}
                      >
                        Approve
                      </Button>
                    </Tooltip>
                    <Tooltip title="Declined">
                      <Button
                        onClick={() => {
                          updateLeaveStatus(selectedLeaveDetails.id, "Declined");
                          setShowApprovedDetailsPopup(false);
                        }}
                        variant="contained"
                        color="primary"
                        style={{ margin: "5px", backgroundColor: "#db3535" }}
                      >
                        Declined
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        open={openEventModal1}
        onClose={() => setOpenEventModal1(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            // padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            width: "350px",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <DialogTitle
            style={{
              textAlign: "center",
              padding: "5px 0",
              backgroundColor: "#1b6e95",
              color: "#fff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            Time Entry Details
          </DialogTitle>
          <CloseIcon
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
              color: "#fff",
              backgroundColor: "#f44336",
              borderRadius: "50%",
              padding: "2px",
            }}
            onClick={() => setOpenEventModal1(false)}
          />
          <DialogContent>
            <div style={{ padding: "10px 20px" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Employee ID</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.name}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.Name}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.date}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>In Time</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.in_time}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Out Time</b></td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.out_time}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px",}}><b>Duration</b></td>
                    <td style={{ padding: "8px", textAlign: "center" }}>:</td>
                    <td style={{ padding: "8px",}}>{selectedTimeEntry.duration}</td>
                  </tr>
                </tbody>
              </table>
              {isAdmin && selectedTimeEntry.is_approved === 1 && (
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ margin: "5px", backgroundColor: "#0a8f45" }}
                    onClick={() => accept(selectedTimeEntry.id)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ margin: "5px", backgroundColor: "#db3535" }}
                    onClick={() => decline(selectedTimeEntry.id)}
                  >
                    Reject
                  </Button>
                </div>
              )}
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default DetailedNotifications;

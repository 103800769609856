import React, { Component } from "react";
import * as workerTimers from "worker-timers";
import "./timer.css";
import Authapi from "../../Services/Authapi";
import swal from 'sweetalert';

class Timer extends Component {
  constructor(props) {

    super(props);
    this.state = {
      timer: 0,
      totaltimer: 0,
      isActive: false,
      isPaused: false,
      startTime: 0,
      stopTime: 0,
      isLoading: false,
      isInitialLoading: true,
    };
    this.countRef = React.createRef(null);
  }

  async componentDidMount() {
    // console.log(this.props.user.Notification_Status);
    //     if (this.props.user.Notification_Status === 1) {
    //       setInterval(() => {
    //         this.breaktimeremainder()
    //       }, 300000)  // 5 minit 
    //     }
    try {
      const authResponse = await Authapi.checkAuth(this.props.user.id);
      if (authResponse.data.user.Break_notification_status === 1) {
        setInterval(() => {
          this.breaktimeremainder();
        }, 300000);  // 5 minutes
        this.breaktimeremainder()
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
    }
    try {
      let timeCount = await Authapi.durTime(this.props.user.id);
      const totalDurationInSec = parseInt(timeCount.totalDurationInSec, 10);

      if (totalDurationInSec > 0) {
        this.setState({ timer: totalDurationInSec, isActive: true, isInitialLoading: false }, this.runCycle);
      } else {
        this.setState({ isInitialLoading: false });
      }
    } catch (error) {
      console.error("Error fetching duration time:", error);
      this.setState({ isInitialLoading: false });
    }

    try {
      let timeCount = await Authapi.dureTime1(this.props.user.id);
      const totalDurationInSec = parseInt(timeCount.totalDurationInSec, 10);

      if (totalDurationInSec > 0) {
        this.setState({ totaltimer: totalDurationInSec }, this.runCycle1);
      } else {
        // this.setState({ isInitialLoading: false });
      }
    } catch (error) {
      console.error("Error fetching duration time:", error);
      // this.setState({ isInitialLoading: false });
    }

    window.addEventListener("beforeunload", this.handleComponentUnmount.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleComponentUnmount.bind(this));
    workerTimers.clearInterval(this.countRef.current);
  }

  handleComponentUnmount() {
    if (this.state.isActive) {
      Authapi.saveTime(this.state.timer);
    }
  }

  breaktimeremainder = async () => {
    if (!this.state.isActive) {
      const result = await Authapi.breaktimeremainder();

      if (result.status === true) {
        if (Notification.permission === "default") {
          Notification.requestPermission().then(permission => {
            if (permission === "granted") {
              new Notification("Notification", {
                body: result.message,
                icon: "/logo.png",
              });
            }
          });
        } else if (Notification.permission === "granted") {
          new Notification("Notification", {
            body: result.message,
            icon: "/logo.png",
          });
        }
      }
    } else {

    }
  };



  runCycle() {
    this.countRef.current = workerTimers.setInterval(() => {
      this.setState((prevState) => ({ timer: prevState.timer + 1 }));
    }, 1000);
  }

  runCycle1() {
    if (this.state.isActive === true) {
      this.countRef.current = workerTimers.setInterval(() => {
        this.setState((prevState) => ({ totaltimer: prevState.totaltimer + 1 }));
      }, 1000);
    }
  }

  handleStart = async () => {
    this.setState({ isLoading: true });
    try {
      await Authapi.inTime();
      this.setState({ isActive: true, startTime: Date.now(), isLoading: false }, this.runCycle);
      this.setState({ isActive: true, startTime: Date.now(), isLoading: false }, this.runCycle1);
    } catch (error) {
      this.setState({ isLoading: false });
      const errorMessage = error.response?.data?.message || "An error occurred while clocking in.";
      const showAddLeaveButton = errorMessage !== "You have already \"Clocked In\" today!! Please refresh the page.";
      swal({
        title: "Error",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "gray-button",
            closeModal: true
          },
          ...(showAddLeaveButton && {
            add: {
              text: "Add Leave",
              value: "add",
              visible: true,
              className: "",
              closeModal: true
            }
          })
        }
      }).then((value) => {
        if (value === "add") {
          window.location.href = "/tickets/Add";
        } else {
          window.location.reload();
        }
      });
    }
  };

  handlePause = async () => {
    this.setState({ isLoading: true });
    await Authapi.outTime();
    this.setState({ isActive: false, stopTime: Date.now(), isLoading: false }, () => {
      workerTimers.clearInterval(this.countRef.current);
      window.location.reload();
    });
  };

  formatTime = () => {
    const { timer } = this.state;
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = Math.floor(timer / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  formatTime1 = () => {
    const { totaltimer } = this.state;
    const getSeconds = `0${totaltimer % 60}`.slice(-2);
    const minutes = Math.floor(totaltimer / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(totaltimer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  render() {
    if (this.state.isInitialLoading) {
      return <div className="stopwatch-card"><p className="timerText">Loading...</p></div>;
    }

    return (


      <div className="stopwatch-card">
        {/* <p className="timerText">{this.formatTime()}</p> */}
        <p className="timerText" style={{ marginLeft: "34px" }}>{this.formatTime1()}</p>
        <div className="buttons" style={{ marginLeft: "10px" }}>
          {!this.state.isActive && (
            <button
              onClick={this.handleStart}
              className="inbutton"
              disabled={this.state.isLoading}
              style={{ color: "green", fontWeight: "bold" }}
            >
              {this.state.isLoading && <span className="spinner"></span>}
              {!this.state.isLoading && "IN"}
            </button>
          )}
          {this.state.isActive && (
            <button
              onClick={this.handlePause}
              className="inbutton"
              disabled={this.state.isLoading}
              style={{
                color: "red", fontWeight: "bold"
              }}
            >
              {this.state.isLoading && <span className="spinner"></span>}
              {!this.state.isLoading && "OUT"}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Timer;

import React, { useState, useEffect, lazy, Suspense } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { Typography, Button, Card, CardContent, Tabs, Tab, Box, Dialog, DialogTitle, DialogContent, TextField, Switch } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AuthCheckComponent from "../../Layouts/Token";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import ls from "local-storage";
import swal from "sweetalert";
// import Grid from "@material-ui/core/Grid";
// import Switch from '@material-ui/core/Switch';
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
// import * as GiIcons from "react-icons/gi";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import * as RiIcons from "react-icons/ri";
// import * as CgIcons from "react-icons/cg";
// import * as BsIcons from "react-icons/bs";
// import * as FiIcons from "react-icons/fi";
// import * as IoIcons from "react-icons/io5";
// import * as HiIcons from "react-icons/hi";
// import * as GrIcons from "react-icons/gr";
// import * as DiIcons from "react-icons/di";
import Authapi from "../../Services/Authapi";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIcons } from '@fortawesome/free-solid-svg-icons';
import "./Settings.css"
//import "./user.css"

const notificationLabels = [
  "Leave Ticket", "Birthday", "Leave Status", "Upcoming Event", "Salary-Slip", "Time-Entry", "New Holiday", "Time-Entry Status", "Resign-Letter",
];

const allIcons = {
  ...FaIcons,
  ...MdIcons,
  // ...GiIcons,
  // ...AiIcons,
  // ...BiIcons,
  // ...RiIcons,
  // ...CgIcons,
  // ...BsIcons,
  // ...FiIcons,
  // ...IoIcons,
  // ...HiIcons,
  // ...GrIcons,
  // ...DiIcons,
  // Add more icon packs here if needed
};

const IconPicker = lazy(() => import("./IconPicker"));

const Settings = (props) => {
  // console.log(props.authUser.user?.Break_notification_status)
  const classes = props;
  const [notificationIcons, setNotificationIcons] = useState({});
  // const [tabIndex, setTabIndex] = useState(0);
  // const [transparentSidebar, setTransparentSidebar] = useState(false);
  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [updatedIcons, setUpdatedIcons] = useState({});
  const [fullDayHours, setFullDayHours] = useState("");
  const [halfDayHours, setHalfDayHours] = useState("");
  const [lopLeaveBalance, setLopLeaveBalance] = useState("");
  const [casualLeaveBalance, setCasualLeaveBalance] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  // const [switchState, setSwitchState] = useState(() => {
  //   const savedStatus = props.authUser.user?.Break_notification_status;
  //   // console.log(savedStatus)
  //   return savedStatus !== null ? savedStatus === 1 : props.authUser?.user?.Break_notification_status === 1;
  // });
  const permissions = (props.authUser && Array.isArray(props.authUser.userAccess))
    ? props.authUser.userAccess
    : [];

  const transformLabel = (label) => {
    return label.replace(/_/g, " ");
  };

  const [editMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditMode(false);
  };

  useEffect(() => {
    const initialIcons = {};
    notificationLabels.forEach((label) => {
      initialIcons[label] = "FaFolderPlus";
    });

    Authapi.getNotificationIcons()
      .then((response) => {
        if (response) {

          const transformedResponse = {};
          for (const key in response) {
            transformedResponse[transformLabel(key)] = response[key];
          }
          setNotificationIcons((prevIcons) => ({
            ...initialIcons,
            ...transformedResponse,
          }));
        } else {
          setNotificationIcons(initialIcons);
        }
      })
      .catch((error) => {
        console.error("Error fetching notification icons:", error);
        setNotificationIcons(initialIcons);
      })
      .finally(() => {
        setLoading(false);
      });

    Authapi.getWorkingHours()
      .then((response) => {
        if (response) {
          setFullDayHours(response.data.full_day_hours);
          setHalfDayHours(response.data.half_day_hours);
        }
      })
      .catch((error) => {
        console.error("Error fetching working hours:", error);
      });

    Authapi.getleavebalance()
      .then((response) => {
        if (response) {
          setCasualLeaveBalance(response.data.casual_leave_balance);
          setLopLeaveBalance(response.data.lop_leave_balance);
        }
      })
      .catch((error) => {
        console.error("Error fetching leave balance:", error);
      });
    Authapi.breaktimeedit()
      .then((response) => {
        if (response) {
          setFromTime(response.data.do_not_disturb_from);
          setToTime(response.data.do_not_disturb_to);
        }
      })
      .catch((error) => {
        console.error("Error fetching leave balance:", error);
      });
  }, []);


  const handleIconChange = (iconKey) => {
    setNotificationIcons((prev) => ({
      ...prev,
      [currentLabel]: iconKey,
    }));
    setUpdatedIcons((prev) => ({
      ...prev,
      [currentLabel]: iconKey,
    }));
    setIconPickerOpen(false);
  };

  const handleSave = () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    Authapi.saveNotificationIcons(updatedIcons)
      .then((response) => {
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Icons saved successfully!",
            confirmButtonText: "OK",
            confirmButtonColor: "#4CAF50",
            customClass: {
              popup: 'swal-popup',
              confirmButton: 'swal-confirm-button',
            },
          }).then(() => {
            props.history.push('/dashboard');
          });
          setUpdatedIcons({});
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error saving icons.",
            confirmButtonText: "OK",
            confirmButtonColor: "#1B6E95",
            customClass: {
              confirmButton: 'swal-confirm-button',
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error saving notification icons:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error saving notification icons.",
          confirmButtonText: "OK",
          confirmButtonColor: "#1B6E95",
          customClass: {
            confirmButton: 'swal-confirm-button',
          },
        });
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const handleSidebarChange = (event) => {
  //   setTransparentSidebar(event.target.checked);
  // };

  const openIconPicker = (label) => {
    setCurrentLabel(label);
    setIconPickerOpen(true);
  };

  const handleWorkingHoursSave = async () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const hours = {
      fullDayHours,
      halfDayHours,
    };

    try {
      const response = await Authapi.saveWorkingHours(hours);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Working hours saved successfully!",
        }).then(() => {
          props.history.push('/dashboard');
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save working hours.",
        });
      }
    } catch (error) {
      console.error("Failed to save working hours:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save working hours.",
      });
    }
  };


  const handleLeaveSettingsSave = async () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const leaveBalance = {
      casualLeaveBalance,
      lopLeaveBalance,
    };

    try {
      const response = await Authapi.saveleavebalance(leaveBalance);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Leave balances saved successfully!",
        }).then(() => {
          props.history.push('/Settings');
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save leave balances.",
        });
      }
    } catch (error) {
      console.error("Failed to save leave balances:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save leave balances.",
      });
    }
  };

  const handleReminderTimeSave = async () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await Authapi.breaktimeadd({ fromTime, toTime });
      Swal.close();
      if (response && response.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Reminder times saved successfully!",
        }).then(() => {
          props.history.push('/dashboard');
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save reminder times.",
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong, Please try later!",
      });
      console.error("Error saving reminder times:", error);
    }
  };
  const statusRow = async (id, status) => {
    const confirm = await swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"]
    });
    if (confirm) {
      swal({
        title: "Loading",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        width: "10%",
        content: {
          element: "img",
          attributes: {
            src: "https://www.boasnotas.com/img/loading2.gif",
            style: "width: 50px;"
          }
        }
      });
      try {
        let statu = await Authapi.BreakNotificationStatus(id, status);
        swal.close();
        if (statu && statu.status === true) {
          if (props.userData) {
            await props.userData();
          }
          window.location.reload();
        }
      } catch (error) {
        console.error('Error updating user status:', error);
        swal("Error", "Failed to update notification status", "error");
      }
    }
  }

  const availableTabs = [
    { permission: "Settings.ReminderSettingsView", index: 0 },
    { permission: "Settings.WorkingHoursView", index: 1 },
    { permission: "Settings.LeaveSettingsView", index: 2 },
    { permission: "Settings.NotificationsView", index: 3 },
  ];

  const defaultTabIndex = availableTabs.find(tab => permissions.includes(tab.permission))?.index || 0;
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  // Function to format time to 12-hour format
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "0%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Settings"
                  primaryPageLink="/Settings"
                />
              </CardContent>
            </Card>
            <Grid container className={classes.root} spacing={2}>
              <div style={{ padding: "20px", width: "100%" }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="settings tabs"
                >
                  {permissions.includes("Settings.ReminderSettingsView") && (
                    <Tab label="Break Time Settings" />
                  )}
                  {permissions.includes("Settings.WorkingHoursView") && (
                    <Tab label="Working Hours" />
                  )}
                  {permissions.includes("Settings.LeaveSettingsView") && (
                    <Tab label="Leaves Settings" />
                  )}
                  {permissions.includes("Settings.NotificationsView") && (
                    <Tab label="Notifications" />
                  )}
                </Tabs>
                {permissions.includes("Settings.ReminderSettingsView") && (
                  <TabPanel value={tabIndex} index={0}>
                    <Grid container spacing={2} style={{ justifyContent: 'flex-start' }}>
                      <Grid item>
                        <Card
                          style={{
                            padding: "20px",
                            background: "linear-gradient(135deg, #18ADF0, #040404)",
                            color: "#fff",
                            borderRadius: "15px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            width: "400px",
                          }}
                        >
                          <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                            Break Time Settings
                          </Typography>
                          <Typography variant="body1" gutterBottom style={{ opacity: 0.8 }}>
                            Here you can set the break times.
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                onClick={() => setDialogOpen(true)}
                                style={{ backgroundColor: "white", color: "black", fontWeight: "bold" }}
                              >
                                <FaIcons.FaClock style={{ marginRight: "5px" }} />
                                See times
                              </Button>
                              <Button
                                variant="contained"
                                onClick={toggleEditMode}
                                style={{ backgroundColor: "#FF9800", color: "#fff", fontWeight: "bold" }}
                              >
                                <MdIcons.MdEdit style={{ marginRight: "5px" }} />
                                Edit
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item>
                        <Card
                          style={{
                            padding: "20px",
                            background: "linear-gradient(135deg, #18ADF0, #040404)",
                            color: "#fff",
                            borderRadius: "15px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            width: "400px",
                          }}
                        >
                          <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                            Break Notification Settings
                          </Typography>
                          <Typography variant="body1" gutterBottom style={{ opacity: 0.8 }}>
                            Toggle break time notifications.
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '18px'  }}>
                              <Typography variant="body1">
                                Break time notification status :
                              </Typography>
                              <Tooltip title="Toggle break notifications">
          <Switch
            checked={props.authUser.user?.Break_notification_status}
            onChange={() => statusRow(props.authUser.user?.id, props.authUser.user?.Break_notification_status ? 0 : 1)}
            color="primary"
            inputProps={{ 'aria-label': 'break notification switch' }}
          />
        </Tooltip>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>

                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                      <DialogTitle
                        style={{ backgroundColor: "#1b6e95", color: "white", fontWeight: "bold", padding: "5px" }}>
                        {editMode ? "Edit Break Times" : "Current Break Times"}
                      </DialogTitle>
                      <CloseIcon
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          cursor: "pointer",
                          color: "#fff",
                          backgroundColor: "#f44336",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                        onClick={handleDialogClose}
                      />
                      <DialogContent
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}>
                        {editMode ? (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="time"
                                size="small"
                                variant="outlined"
                                value={fromTime}
                                onChange={(e) => setFromTime(e.target.value)}
                                label="From Time"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="time"
                                size="small"
                                variant="outlined"
                                value={toTime}
                                onChange={(e) => setToTime(e.target.value)}
                                label="To Time"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  handleDialogClose();
                                  handleReminderTimeSave();
                                }}
                                style={{ backgroundColor: "#1B6E95", color: "#fff", fontWeight: "bold", marginRight: "6px" }}
                              >
                                Save
                              </Button>
                              <Button
                                onClick={handleDialogClose}
                                style={{ color: "#000000" }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Typography variant="body1">
                              From Time: {formatTime(fromTime)}
                            </Typography>
                            <Typography variant="body1">
                              To Time: {formatTime(toTime)}
                            </Typography>
                          </>
                        )}
                      </DialogContent>
                    </Dialog>
                  </TabPanel>
                )}

                {permissions.includes("Settings.WorkingHoursView") && (
                  <TabPanel value={tabIndex} index={1}>
                    <Grid container style={{ display: "grid", justifyContent: 'flex-start', }}>
                      <Card
                        style={{
                          padding: "20px",
                          background: "linear-gradient(135deg, #18ADF0, #040404)",
                          color: "#fff",
                          borderRadius: "15px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          // maxWidth: "500px",
                          width: "400px",
                          margin: "auto",
                        }}
                      >
                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                          Working Hours Settings
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ opacity: 0.8 }}>
                          Here you can set the half day and full day hours.
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <Button
                              variant="contained"
                              onClick={() => setDialogOpen(true)}
                              style={{ backgroundColor: "white", color: "black", fontWeight: "bold" }}
                            >
                              <MdIcons.MdTimer style={{ marginRight: "5px" }} />
                              See hours
                            </Button>
                            <Button
                              variant="contained"
                              onClick={toggleEditMode}
                              style={{ backgroundColor: "#FF9800", color: "#fff", fontWeight: "bold" }}
                            >
                              <MdIcons.MdEdit style={{ marginRight: "5px" }} />
                              Edit
                            </Button>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                      <DialogTitle
                        style={{ backgroundColor: "#1b6e95", color: "white", fontWeight: "bold", padding: "5px", }}>{editMode ? "Edit Working Hours" : "Current Working Hours"}</DialogTitle>
                                  <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={handleDialogClose}
              />
                      <DialogContent
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}>
                        {editMode ? (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={fullDayHours}
                                onChange={(e) => setFullDayHours(e.target.value)}
                                label="Min Full-Day Hours"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={halfDayHours}
                                onChange={(e) => setHalfDayHours(e.target.value)}
                                label="Min Half-Day Hours"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  handleDialogClose();
                                  handleWorkingHoursSave();
                                }}
                                style={{ backgroundColor: "#1B6E95", color: "#fff", fontWeight: "bold", marginRight: "6px" }}
                              >
                                Save
                              </Button>
                              <Button
                                // variant="contained"
                                onClick={handleDialogClose}
                                style={{ color: "#000000" }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Typography variant="body1">
                              Full Day Hours : {fullDayHours}
                            </Typography>
                            <Typography variant="body1">
                              Half Day Hours : {halfDayHours}
                            </Typography>
                          </>
                        )}
                      </DialogContent>
                    </Dialog>
                  </TabPanel>
                )}

                {permissions.includes("Settings.LeaveSettingsView") && (
                  <TabPanel value={tabIndex} index={2}>
                    <Grid container style={{ display: "grid", justifyContent: 'flex-start' }}>
                      <Card
                        style={{
                          padding: "20px",
                          background: "linear-gradient(135deg, #18ADF0, #040404)",
                          color: "#fff",
                          borderRadius: "15px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          // maxWidth: "500px",
                          width: "400px",
                          margin: "auto",
                        }}
                      >
                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                          Leave Settings
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ opacity: 0.8 }}>
                          Here you can set the leave balances.
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <Button
                              variant="contained"
                              onClick={() => setDialogOpen(true)}
                              style={{ backgroundColor: "white", color: "black", fontWeight: "bold" }}
                            >
                              <FaIcons.FaTicketAlt style={{ marginRight: "5px" }} />
                              See balances
                            </Button>
                            <Button
                              variant="contained"
                              onClick={toggleEditMode}
                              style={{ backgroundColor: "#FF9800", color: "#fff", fontWeight: "bold" }}
                            >
                              <MdIcons.MdEdit style={{ marginRight: "5px" }} />
                              Edit
                            </Button>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                      <DialogTitle
                        style={{ backgroundColor: "#1b6e95", color: "white", fontWeight: "bold", padding: "5px" }}>
                        {editMode ? "Edit Leave Balances" : "Current Leave Balances"}
                      </DialogTitle>
                      <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={handleDialogClose}
              />
                      <DialogContent
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}>
                        {editMode ? (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={lopLeaveBalance}
                                onChange={(e) => setLopLeaveBalance(e.target.value)}
                                label="Loss of Pay Leave Balance"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={casualLeaveBalance}
                                onChange={(e) => setCasualLeaveBalance(e.target.value)}
                                label="Casual Leave Balance"
                                style={{ backgroundColor: "#fafafa", marginBottom: "10px" }}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  handleDialogClose();
                                  handleLeaveSettingsSave();
                                }}
                                style={{ backgroundColor: "#1B6E95", color: "#fff", fontWeight: "bold", marginRight: "6px" }}
                              >
                                Save
                              </Button>
                              <Button
                                onClick={handleDialogClose}
                                style={{ color: "#000000" }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Typography variant="body1">
                              Loss of Pay Leave Balance: {lopLeaveBalance}
                            </Typography>
                            <Typography variant="body1">
                              Casual Leave Balance: {casualLeaveBalance}
                            </Typography>
                          </>
                        )}
                      </DialogContent>
                    </Dialog>
                  </TabPanel>
                )}

                {permissions.includes("Settings.NotificationsView") && (
                  <TabPanel value={tabIndex} index={3}>
                    <Grid container style={{ display: "grid", justifyContent: 'flex-start' }}>
                      <Card
                        style={{
                          padding: "20px",
                          background: "linear-gradient(135deg, #18ADF0, #040404)",
                          color: "#fff",
                          borderRadius: "15px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          width: "400px",
                          margin: "auto",
                        }}
                      >
                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                          Notification Icons
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ opacity: 0.8 }}>
                          Here you can set the notification icons.
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                          <Button
  variant="contained"
  onClick={() => setDialogOpen(true)}
  style={{ backgroundColor: "white", color: "black", fontWeight: "bold" }}
>
  <FontAwesomeIcon icon={faIcons} style={{ marginRight: "5px" }} />
  See Icons
</Button>
                            <Button
                              variant="contained"
                              onClick={toggleEditMode}
                              style={{ backgroundColor: "#FF9800", color: "#fff", fontWeight: "bold" }}
                            >
                              <MdIcons.MdEdit style={{ marginRight: "5px" }} />
                              Edit
                            </Button>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                      <DialogTitle
                        style={{ backgroundColor: "#1b6e95", color: "white", fontWeight: "bold", padding: "5px" }}>
                        {editMode ? "Edit Notification Icons" : "Current Notification Icons"}
                      </DialogTitle>
                      <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={handleDialogClose}
              />
                      <DialogContent
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        {editMode ? (
                          <Grid container spacing={2}>
                            {notificationLabels.map((label) => (
                              <Grid item xs={6} key={label}>
                                <Card
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "10px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Typography variant="body1">{label}</Typography>
                                  <Button onClick={() => openIconPicker(label)}>
                                    {allIcons[notificationIcons[label]]
                                      ? React.createElement(
                                        allIcons[notificationIcons[label]],
                                        { size: 30, color: "#1B6E95" }
                                      )
                                      : React.createElement(
                                        allIcons["FaFolderPlus"],
                                        { size: 30, color: "#1B6E95" }
                                      )}
                                  </Button>
                                </Card>
                              </Grid>
                            ))}
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  handleDialogClose();
                                  handleSave();
                                }}
                                style={{ backgroundColor: "#1B6E95", color: "#fff", fontWeight: "bold", marginRight: "6px" }}
                              >
                                Save
                              </Button>
                              <Button
                                onClick={handleDialogClose}
                                style={{ color: "#000000" }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2}>
                            {notificationLabels.map((label) => (
                              <Grid item xs={6} key={label}>
                                <Card
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "10px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Typography variant="body1">{label}</Typography>
                                  <Button>
                                    {allIcons[notificationIcons[label]]
                                      ? React.createElement(
                                        allIcons[notificationIcons[label]],
                                        { size: 30, color: "#1B6E95" }
                                      )
                                      : React.createElement(
                                        allIcons["FaFolderPlus"],
                                        { size: 30, color: "#1B6E95" }
                                      )}
                                  </Button>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </DialogContent>
                    </Dialog>
                  </TabPanel>
                )}
              </div>
            </Grid>
          </div>
        }
      />
      <Footer />
      <Dialog open={iconPickerOpen} onClose={() => setIconPickerOpen(false)}>
        <DialogTitle color="#1B6E95">Select an Icon</DialogTitle>
        <DialogContent>
          <Suspense fallback={<div>Loading...</div>}>
            <IconPicker
              onSelect={handleIconChange}
              onCancel={() => setIconPickerOpen(false)}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default Settings;
import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentYear = new Date().getFullYear();

    // Define styles
    const styles = {
      body: {
        height: "100%",
        margin: 0,
      },
      footer: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#1E7BA6",
        color: "#FFFFFF",
        textAlign: "center",
        padding: "12px -1",
        marginTop: "20px",
      },
      content: {
        paddingBottom: "50px", // Adjust this value to the height of your footer
      },
    };

    return (
      <div style={styles.body}>
        <div style={styles.content}>
          {/* Your main content goes here */}
        </div>
        <div style={styles.footer}>
          <p>Copyright@{currentYear}. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Footer;